import React from 'react';

const Privacy = () => {
    return (
        <div>
            <h1 style={{ textAlign: "center" }}>НУУЦЛАЛЫН БОДЛОГО</h1>
    <h3 style={{ textAlign: "center" }}>НЭГ. ЕРӨНХИЙ МЭДЭЭЛЭЛ, МЭДЭЭЛЭЛ ЦУГЛУУЛАХ</h3>

    <ol>
        <li>Энэхүү Нууцлалын Бодлого нь "АГУУЧИН ВЕЛНЕСС" ХХК (цаашид "Компани" гэж нэрлэх) Таны хувийн мэдээллийг хэрхэн цуглуулах, ашиглах, хамгаалах тухай баримт бичиг юм. Компани нь https://bayasaltai.com/ вэбсайт болон мобайл аппликэйшн (хамтад нь "Үйлчилгээ" гэж нэрлэх)-д бүртгүүлсэн бүх хэрэглэгчдийн хувийн нууцлалыг энэхүү баримт бичигт зааснаар хадгалж, хамгаална. Мөн Компани нь хэрэглэгчийн мэдээллийг уг нууцлалын бодлогод заасны дагуу хэрэглэнэ.</li>
        <li>Компани нь энэхүү нууцлалын бодлогод тусгасанаас өөрөөр болон Монгол улсын хуульд зааснаас бусад ямар ч тохиолдолд хэрэглэгчийн аливаа мэдээллийг гуравдагч этгээдэд дамжуулахгүй. Аппликэйшныг хэрэглэж байгаа бол Таныг уг нууцлалын бодлогыг зөвшөөрсөн гэж үзнэ. Компани нь Нууцлалын бодлогод өөрчлөлт оруулж болно. Өөрчлөлт орсон тухай энэ бодлогын дээд хэсэгт байрлах Сүүлд шинэчилсэн огноог өөрчлөх болон бусад хэлбэрээр мэдэгдэнэ. Тухайлбал: аппликэйшнаар дамжуулан мэдэгдэл байршуулах эсвэл имэйлээр мэдэгдэл илгээх зэрэг байж болно.</li>
        <li>Хэрэглэгч өөрөө өгөх мэдээлэл: Бид таныг платформд бүртгүүлж, платформыг ашиглах явцад мэдээллийг цуглуулдаг. Жишээлбэл: бүртгүүлэх, орлого зарлагын гүйлгээ хийх, санал асуулгад оролцох, манай олон нийтийн хуудсуудад мэдээ нийтлэх эсхүл бусад хэлбэрээр бидэнтэй харилцах үед таны мэдээллийг цуглуулдаг.</li>
        <li>
            <div>
                Танаас дараах төрлийн мэдээллийг шаардлагатай тохиолдолд авч болно.
            </div>
            <ul>
                <li>Таны овог, нэр, шуудангийн хаяг, утасны дугаар, имэйл хаяг зэрэг холбоо барих мэдээлэл.</li>
                <li>Банкны дансны дугаар зэрэг санхүүгийн мэдээлэл.</li>
                <li>Иргэний үнэмлэх</li>
                <li>Таны төрсөн он сар өдөр, хүйс, ажил мэргэжил, ажил эрхлэлтийн байдал болон таны өөрөө өгсөн бусад мэдээлэл гэх мэт хувийн мэдээлэл зэрэг хамаарна.</li>
            </ul>
        </li>
        <li>Бусад эх сурвалжаас авсан мэдээлэл: Мөн бид бусад эх сурвалжаас мэдээлэл авч, аппликэйшнаар дамжуулан цуглуулсан мэдээлэлдээ нэгтгэн оруулж болно.</li>
    </ol>


    <h3 style={{ textAlign: "center" }}>ХОЁР. МЭДЭЭЛЭЛ АШИГЛАХ</h3>

    <p>Системийн үндсэн зааварчилгааны дагуу Хэрэглэгчийн оруулсан мэдээлэл нь Хэрэглэгчийн өмч бөгөөд “Баясалтай” аппликэйшн Хэрэглэгчийн зөвшөөрөлгүйгээр дор дурдсан нөхцөлөөс бусад хэлбэрээр ашиглахгүй болно.</p>

    <ol>
        <li>Захиалсан бараа бүтээгдэхүүнийг хүргэх, захиалгатай холбоотой тодруулах зүйл гарсан тохиолдолд таны мэдээллийг дараах байдлаар ашиглана. Үүнд: таны гар утсанд холбогдох, цахим шууданд мэдээлэл илгээх, хэрэглэгчийн бүртгүүлсэн хаягаар хүргэлт хийх гэх мэт.</li>
        <li>Системийн ашиг тусын тулд системийн хяналт, сайжруулалт, хөгжүүлэлт, тест хийх</li>
        <li>Шинэ бүтээгдэхүүн ба модуль хөгжүүлэх болон тест хийх</li>
        <li>Техникийн засвар хийх</li>
        <li>Системийн судалгаа ба хяналтад зориулж (нийт хэрэглэгч, захиалга гэх мэт)</li>
        <li>Хэрэглэгчид шинэ бүтээгдэхүүн, хямдралын мэдээллийг хүргэх зориулалтаар тус тус</li>
    </ol>

    <h3 style={{ textAlign: "center" }}>ГУРАВ. МЭДЭЭЛЭЛ ДАМЖУУЛАХ</h3>

        <ol>
            <li>“Баясалтай" аппликэйшн нь Монгол улсын Үндсэн хууль, Хувь хүний нууцыг хамгаалах тухай хууль болон бусал хууль тогтоомжийн хүрээнд хэрэглэгчийн хувийн мэдээллийг гуравдагч этгээдэд ашиглуулахгүй, түгээхгүй, худалдахгүй бөгөөд үйлчилгээний хэрэгцээнээс гадуур аливаа ашиг олох зорилгоор ашиглахгүй болно.</li>
        </ol>

    <h3 style={{ textAlign: "center" }}>ДӨРӨВ. ХЭРЭГЛЭГЧИЙН МЭДЭЭЛЛИЙГ ХАДГАЛАХ</h3>

    <ol>
        <li>“Баясалтай" аппликэйшн нь Хэрэглэгчийн системд оруулсан мэдээллийг өөрийн серверт хадгална. Хэрэглэгч нь системд нэвтрэх нэр, нууц үгийг бусдад задруулахгүй байх үүрэгтэй.</li>
        <li>Нэвтрэх эрхээ бусдад алдсанаас үүдэлтэй Хэрэглэгчийн хуудаст үүссэн аливаа хор, хохирлыг “Баясалтай" аппликэйшн хариуцахгүй болно. “Баясалтай" аппликэйшн нь Хэрэглэгчийн хандах эрхийг ашиглан мэдээлэлд нэвтрэх боломжгүй болохыг анхааруулж байна.</li>
    </ol>

    <h3 style={{ textAlign: "center" }}>ТАВ. ХЭРЭГЛЭГЧ МЭДЭЭЛЛЭЭ УДИРДАХ</h3>
    <ol>
        <li>Хэрэглэгч хувийн мэдээлэл болон өөрийн дансныхаа тохиргоог хэдийд ч өөрчлөх боломжтой.</li>
        <li>Хэрэглэгч системийн цахим шуудан (и-мэйл) үйлчилгээнээс өөрийгөө хасуулах боломжтой.</li>
        <li>Хэрэв хэрэглэгч өөрийн эрхээ идэвхгүй болгосон бол хэрэглэгчийн мэдээллийг системд дүрслэхгүй. Систем хэрэглэгчийн мэдээллийг хэрэглэгч идэвхтэй горимд орох хүртэл хадгална.</li>
    </ol>




    <h3 style={{ textAlign: "center" }}>ЗУРГАА. НУУЦЛАЛЫН БОДЛОГЫН ШИНЧЛЭЛТ</h3>
    <ol>
        <li>“Баясалтай" аппликэйшн нь Нууцлалын бодлогод өөрчлөлт оруулах эрхтэй. Нууцлалын бодлогод өөрчлөлт оруулсан тохиолдолд тухай бүр “Баясалтай" аппликэйшн дээр нийтэд нээлттэй нийтлэх бөгөөд Хэрэглэгч нь танилцах үүрэг хүлээнэ.</li>
        <li>Хэрэглэгчийг захиалга хийгдэх үед хүчинтэй байсан “Нууцлалын бодлого” захиалгыг хүргэх шатанд өөрчлөгдсөн тохиолдолд өмнөх “Нууцлалын бодлого”-г бараа бүтээгдэхүүнийг хүлээж авах хүртэлх хугацаанд хүчинтэйд тооцно.</li>
    </ol>
    </div>
    )
}

export default Privacy;