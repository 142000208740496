import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/payment`}
          component={lazy(() => import(`./payment`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/recipe`}
          component={lazy(() => import(`./recipe`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/ingredient`}
          component={lazy(() => import(`./ingredient`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/category`}
          component={lazy(() => import(`./category`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/instruction`}
          component={lazy(() => import(`./instruction`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/feedback`}
          component={lazy(() => import(`./feedback`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/post`}
          component={lazy(() => import(`./post`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/program`}
          component={lazy(() => import(`./program`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/market`}
          component={lazy(() => import(`./market`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/banner`}
          component={lazy(() => import(`./banner`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/user`}
          component={lazy(() => import(`./user`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mass`}
          component={lazy(() => import(`./mass`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mass`}
          component={lazy(() => import(`./mass`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/greeting`}
          component={lazy(() => import(`./greeting`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/announcement`}
          component={lazy(() => import(`./announcement`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
}

export default React.memo(AppViews);