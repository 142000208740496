import React from 'react';

const Download = () => {

    if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        window.location = "itms-apps://itunes.apple.com/us/app/id1605056590?mt=8";
    }
    
    window.location.href = "market://details?id=com.bayasaltai";
    
    return (
        <></>
    )
}

export default Download;