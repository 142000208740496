import { 
  DashboardOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  ApartmentOutlined,
  ContainerOutlined,
  SettingOutlined,
  GroupOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WechatOutlined,
  AppstoreAddOutlined,
  TabletOutlined,
  NotificationOutlined,
  MessageOutlined,
  PushpinOutlined,
  BarcodeOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
	{
		key: 'main',
		title: 'sidenav.main',
		icon: SettingOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'home',
				path: `${APP_PREFIX_PATH}/home`,
				title: 'sidenav.home',
				icon: DashboardOutlined,
				breadcrumb: false,
				submenu: []
			}, {
				key: 'payment',
				path: `${APP_PREFIX_PATH}/payment`,
				title: 'sidenav.payment',
				icon: BarcodeOutlined,
				breadcrumb: false,
				submenu: []
			}, {
				key: 'user',
				path: `${APP_PREFIX_PATH}/user`,
				title: 'sidenav.user',
				icon: UserOutlined,
				breadcrumb: false,
				submenu: []
			}, {
				key: 'recipe',
				path: `${APP_PREFIX_PATH}/recipe`,
				title: 'sidenav.recipe',
				icon: ReconciliationOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'recipes',
						path: `${APP_PREFIX_PATH}/recipe/items`,
						title: 'sidenav.recipe',
						icon: '',
						breadcrumb: false,
						submenu: []
					}, {
						key: 'recipe_prices',
						path: `${APP_PREFIX_PATH}/recipe/price`,
						title: 'sidenav.recipe.price',
						icon: '',
						breadcrumb: false,
						submenu: []
					}
				]
			}, {
				key: 'market',
				path: `${APP_PREFIX_PATH}/market`,
				title: 'sidenav.market',
				icon: ShopOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'products',
						path: `${APP_PREFIX_PATH}/market/product`,
						title: 'sidenav.market.product',
						icon: '',
						breadcrumb: false,
						submenu: []
					}, {
						key: 'orders',
						path: `${APP_PREFIX_PATH}/market/order`,
						title: 'sidenav.market.order',
						icon: '',
						breadcrumb: false,
						submenu: []
					}
				]
			}, {
				key: 'program',
				path: `${APP_PREFIX_PATH}/program`,
				title: 'sidenav.program',
				icon: ApartmentOutlined,
				breadcrumb: false,
				submenu: [],
			}, {
				key: 'feedback',
				path: `${APP_PREFIX_PATH}/feedback`,
				title: 'sidenav.feedback',
				icon: WechatOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'mass_notification',
				path: `${APP_PREFIX_PATH}/mass`,
				title: 'sidenav.mass_notification',
				icon: NotificationOutlined,
				breadcrumb: false,
				submenu: []
			},
		]
	},
	{
		key: 'utility',
		title: 'sidenav.utilities',
		icon: SettingOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'category',
				path: `${APP_PREFIX_PATH}/category`,
				title: 'sidenav.category',
				icon: GroupOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'ingredient',
				path: `${APP_PREFIX_PATH}/ingredient`,
				title: 'sidenav.ingredient',
				icon: ContainerOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'instruction',
				path: `${APP_PREFIX_PATH}/instruction`,
				title: 'sidenav.instruction',
				icon: UnorderedListOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'post',
				path: `${APP_PREFIX_PATH}/post`,
				title: 'sidenav.post',
				icon: AppstoreAddOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'banner',
				path: `${APP_PREFIX_PATH}/banner`,
				title: 'sidenav.banner',
				icon: TabletOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'greeting',
				path: `${APP_PREFIX_PATH}/greeting`,
				title: 'sidenav.greeting',
				icon: MessageOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'announcement',
				path: `${APP_PREFIX_PATH}/announcement`,
				title: 'sidenav.announcement',
				icon: PushpinOutlined,
				breadcrumb: false,
				submenu: []
			},
		],
	}
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
