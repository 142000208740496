import fetch from 'auth/FetchInterceptor'

const JwtAuthService = {}

JwtAuthService.login = function (data) {
	return fetch({
		url: '/api/auth/system/login',
		method: 'post',
		headers: {
			'public-request': 'true'
		},
		data: data
	})
}

JwtAuthService.logout = function (data) {
	return fetch({
		url: '/api/auth/system/logout',
		method: 'post',
		headers: {
			'public-request': 'true'
		},
		data: data
	})
}

JwtAuthService.check = function () {
	return fetch({
		url: '/api/auth/user',
		method: 'get',
		headers: {
			'public-request': 'true'
		},
	})
}

export default JwtAuthService